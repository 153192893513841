import React from 'react';
import { Content, FeedbackForm, Header } from '../../components';
import { useSelector } from 'react-redux';
export const Feedback = () => {
  const i18n = useSelector((state) => state.app.i18n || []);
  return (
    <>
      <Header title={i18n.feedback} />
      <Content>
        <FeedbackForm />
      </Content>
    </>
  );
};
