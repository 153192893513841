import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
export const Footer = () => {
  const menu = useSelector((state) => state.app.blocks.menu || []);
  let location = useLocation();
  return (
    <div id="footer-bar" className="footer-bar-1">
      {menu.map((m, index) => (
        <Link to={m.link} className={m.link === location.pathname ? 'active-nav' : ''} key={index}>
          <i className={`${m.icon}`} />
          <span>{m.title}</span>
        </Link>
      ))}
    </div>
  );
};
