import React from 'react';
import { Content, Header } from '../../components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export const RoomServices = () => {
  const menu = useSelector((state) => state.app.blocks.menu || []);
  const roomService = useSelector((state) => state.app.blocks.roomService || []);
  return (
    <>
      <Header title={(menu.find((m) => m.link === '/room-services') || {}).title} />
      <Content>
        <>
          <div className="card card-style">
            <div className="content mb-3">
              <div className="row text-center mb-0">
                {roomService.map((item, index) => (
                  <Link to={item.link} key={index} className="col-12 p-0">
                    <div className={`card card-style ml-2 mb-3 pb-3 bg-${index + 1}`}>
                      <i className={`${item.icon} color-white fa-3x mt-5`} />
                      <h6 className="pt-4 color-white font-weight-normal font-18">{item.title}</h6>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </>
      </Content>
    </>
  );
};
