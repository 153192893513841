import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSetDark, appSetShowPreview } from '../../store/actions';
export const Preview = () => {
  const isDark = useSelector((state) => state.app.isDark);
  const name = useSelector((state) => state.app.name || null);
  const i18n = useSelector((state) => state.app.i18n || {});
  const [isDarkTheme] = useState(isDark);
  const dispatch = useDispatch();
  useEffect(() => {
    !isDarkTheme && dispatch(appSetDark(true));
    return () => {
      !isDarkTheme && dispatch(appSetDark(false));
    };
  }, [dispatch, isDarkTheme]);
  return (
    <div id="page" style={{ minHeight: window.innerHeight }}>
      <div className="page-content pb-0" style={{ minHeight: window.innerHeight }}>
        <div className="card" style={{ height: window.innerHeight }}>
          <div className="show-on-theme-light card-center text-center">
            <img className="preload-img text-white" alt={name} src={i18n.logo2} width="90" />
            <h1 className="color-black font-40 font-800 mt-3">{name}</h1>
            <p className="color-black" dangerouslySetInnerHTML={{ __html: i18n.slogan2 }} />

            <p className="boxed-text-xl font-14 font-400 line-height-l color-black">{i18n.slogan}</p>
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                dispatch(appSetShowPreview(false));
              }}
              className="btn btn-m font-900 text-uppercase rounded-l btn-center-l bg-highlight"
            >
              {i18n.start}
            </a>
          </div>

          <div className="show-on-theme-dark card-center text-center">
            <img className="preload-img" alt={name} src={i18n.logo2} width="90" />
            <h1 className="color-white font-40 mt-3">{name}</h1>
            <p className="color-white" dangerouslySetInnerHTML={{ __html: i18n.slogan2 }} />

            <p className="boxed-text-xl font-14 line-height-l color-white">{i18n.slogan}</p>
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                dispatch(appSetShowPreview(false));
              }}
              className="btn btn-m font-900 text-uppercase rounded-l btn-center-l bg-highlight"
            >
              {i18n.start}
            </a>
          </div>

          <div className="card-overlay bg-theme opacity-85" />
          <div className="card-overlay-infinite preload-img" style={{ backgroundImage: `url(${i18n.preloader})` }} />
        </div>
      </div>
    </div>
  );
};
