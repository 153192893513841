import React from 'react';
import { CartForm, Content, Header } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../hooks/useCart';
import { appAddCart } from '../../store/actions';
export const Cart = () => {
  const products = useSelector((state) => state.app.blocks.products || []);
  const i18n = useSelector((state) => state.app.i18n || {});
  const dispatch = useDispatch();
  const cart = useCart();
  return (
    <>
      <Header title={i18n.cart} />
      <Content>
        <>
          <div className="card card-style">
            {Object.keys(cart).length === 0 && (
              <div className="m-0 alert alert-small rounded-s shadow-xl bg-red2-dark">
                <span>
                  <i className="fa fa-exclamation-triangle" />
                </span>
                <strong>{i18n['empty-cart']}</strong>
              </div>
            )}
            {Object.keys(cart).length > 0 && (
              <div className="content">
                <div className="d-flex">
                  <div className="align-self-center">
                    <h2 className="mb-0">{i18n.cart}</h2>
                  </div>
                  <div className="align-self-center ml-auto">
                    <h6 className="mb-0 opacity-30 font-13">
                      {i18n.total}{' '}
                      <em>
                        {Object.keys(cart)
                          .map((k, index) => {
                            const item = products.find((p) => p.alias === k);
                            return item ? item.price || 0 : 0;
                          })
                          .reduce((item, acc) => acc + item, 0)}
                      </em>{' '}
                      {i18n.currency}
                    </h6>
                  </div>
                </div>

                <div className="divider mt-3 mb-3" />

                {Object.keys(cart).map((k, index) => {
                  const item = products.find((p) => p.alias === k);
                  if (!item) {
                    return <></>;
                  }
                  return (
                    <a
                      href="/"
                      key={index}
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                      className="d-flex mb-3"
                    >
                      <div className="align-self-center">
                        <h1 className="pr-3 font-40 font-900 opacity-30">{cart[k]}</h1>
                      </div>
                      <div className="align-self-center">
                        <h4 className="mb-n1 font-15">{item.title}</h4>
                        <p className="font-10 opacity-50">
                          {[`${item.price} ${i18n.currency}`, item.weight].filter((v) => v).join(' / ')}
                        </p>
                      </div>
                      <div className="align-self-center ml-auto">
                        <i
                          className="fa fa-times-circle fa-2x color-highlight"
                          onClick={(event) => {
                            event.preventDefault();
                            const res = {};
                            Object.keys(cart)
                              .filter((k) => k !== item.alias)
                              .forEach((k) => (res[k] = cart[k] || 1));
                            dispatch(appAddCart(res));
                          }}
                        />
                      </div>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          {Object.keys(cart).length > 0 && <CartForm />}
        </>
      </Content>
    </>
  );
};
