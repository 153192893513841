import React from 'react';
import { FooterPage, Header } from '../../components';
import { useSelector } from 'react-redux';
export const HotSiberia = () => {
  const menu = useSelector((state) => state.app.blocks.menu || []);
  const hotsiberia = useSelector((state) => state.app.blocks.hotsiberia || []);
  return (
    <>
      <Header title={(menu.find((m) => m.link === '/hotsiberia') || {}).title} />
      <div className="page-content header-clear-medium" style={{ minHeight: window.innerHeight }}>
        <>
          {hotsiberia.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className="card card-style bg-14" style={{ height: 200, backgroundImage: `url('${item.icon}')` }}>
                  <div className="card-center pl-3">
                    <h1 className="color-white font-weight-normal font-18 mb-0">{item.title.toUpperCase()}</h1>
                  </div>
                  <div className="card-overlay bg-black opacity-60" />
                </div>
                <div className="card card-style">
                  <div className="content mb-2">
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
          <FooterPage />
        </>
      </div>
    </>
  );
};
