import React from 'react';
import { Content, Header } from '../../components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export const Home = () => {
  const title = useSelector((state) => state.app.name || '');
  const app = useSelector((state) => state.app.blocks.app || []);
  return (
    <>
      <Header title={title} />
      <Content>
        <>
          <div className="card card-style">
            <div className="content mb-3">
              <div className="row mx-auto mt-3 mb-0">
                {app.map((item, index) =>
                  item.link && (item.link.search('http://') > -1 || item.link.search('https://') > -1) ? (
                    <a
                      href={item.link}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textOverflow: 'ellipsis',
                        display: 'block',
                        whiteSpace: 'nowrap',
                      }}
                      className="btn btn-m btn-block btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-red2-dark btn-icon text-left"
                    >
                      <i className={`${item.icon} font-15 rounded-xl text-center`} />
                      {item.title}
                    </a>
                  ) : (
                    <Link
                      key={index}
                      to={item.link}
                      style={{
                        textOverflow: 'ellipsis',
                        display: 'block',
                        whiteSpace: 'nowrap',
                      }}
                      className="btn btn-m btn-block btn-full mb-3 rounded-xl text-uppercase font-900 shadow-s bg-red2-dark btn-icon text-left"
                    >
                      <i className={`${item.icon} font-15 rounded-xl text-center`} />
                      {item.title}
                    </Link>
                  ),
                )}
              </div>
            </div>
          </div>
        </>
      </Content>
    </>
  );
};
