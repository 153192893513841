import React from 'react';
import PropTypes from 'prop-types';
import { FooterPage } from '..';
export const Content = (props) => {
  const { children, showFooter = true } = props;
  return (
    <div className="page-content header-clear-medium" style={{ minHeight: window.innerHeight }}>
      {children}
      {showFooter && <FooterPage />}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.element.isRequired,
  showFooter: PropTypes.bool,
};
