import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { appSetDark, appSetShowSelectLang, appSetShowSideBar } from '../../store/actions';
import { useCart } from '../../hooks/useCart';
import { Link, useHistory, useLocation } from 'react-router-dom';
export const Header = (props) => {
  const isDark = useSelector((state) => state.app.isDark);
  const showSideBar = useSelector((state) => state.app.showSideBar);
  const lang = useSelector((state) => state.app.lang);
  const dispatch = useDispatch();
  const cart = useCart();
  const history = useHistory();
  const location = useLocation();
  const isHome = location.pathname === '/';
  const count = Object.keys(cart).length;
  return (
    <div className="header header-fixed header-logo-center">
      <Link to="/" className="header-title">
        {props.title}
      </Link>
      {!isHome && (
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();
            history.goBack();
          }}
          className="header-icon header-icon-1"
        >
          <i className="fas fa-chevron-left" />
        </a>
      )}
      <a
        href="/"
        onClick={(event) => {
          event.preventDefault();
          dispatch(appSetShowSideBar(!showSideBar));
        }}
        className={`header-icon ${isHome ? 'header-icon-1' : 'header-icon-2'}`}
      >
        <i className="fas fa-bars" />
        {count > 0 && <span className="badge bg-highlight">{count}</span>}
      </a>
      <a
        href="/"
        onClick={(event) => {
          event.preventDefault();
          dispatch(appSetShowSelectLang(true));
        }}
        className="header-icon header-icon-3"
      >
        <i>
          <img
            width="20"
            alt={lang}
            src={`/assets/front/images/flags/${lang === 'ru' ? 'Russia' : 'United-States'}.png`}
          />
        </i>
      </a>
      <a
        href="/"
        onClick={(event) => {
          event.preventDefault();
          dispatch(appSetDark(!isDark));
        }}
        className="header-icon header-icon-4"
      >
        <i className={isDark ? 'far fa-lightbulb' : 'fas fa-lightbulb'} />
      </a>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};
