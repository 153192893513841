import React, { useEffect, useState } from 'react';
import { Content, Header } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../../hooks/useCart';
import { appAddCart, appSetShowAddToCart } from '../../../store/actions';
export const RoomServicesMenu = () => {
  const roomService = useSelector((state) => state.app.blocks.roomService || []).find(
    (m) => m.link === '/room-services/menu',
  );
  const products = useSelector((state) => state.app.blocks.products || []);
  const i18n = useSelector((state) => state.app.i18n || {});
  const dispatch = useDispatch();
  const [showAddToCart, setShowAddToCart] = useState(null);
  const [qty, setQty] = useState('1');
  useEffect(() => {
    setQty('1');
    if (showAddToCart) {
      dispatch(appSetShowAddToCart(false));
    }
  }, [showAddToCart, dispatch]);
  const cart = useCart();
  return (
    <>
      <Header title={roomService.title} />
      <Content>
        <>
          <div className="card card-style bg-14" style={{ height: 200, backgroundImage: `url('${roomService.img}')` }}>
            <div className="card-center pl-3">
              <h1 className="color-white font-28 mb-0">{roomService.title.toUpperCase()}</h1>
              {roomService.content && (
                <div className="color-white mt-n1 mb-0" dangerouslySetInnerHTML={{ __html: roomService.content }} />
              )}
            </div>
            <div className="card-overlay bg-black opacity-60" />
          </div>
          {products
            .filter((p) => !p.parent_id)
            .map((item, index) => {
              const children = products.filter((p) => p.parent_id === item.id);
              return (
                <div className="card card-style" key={index}>
                  <div className="card-header bg-red2-dark">
                    <h6 className="color-white pt-2">{item.title}</h6>
                  </div>
                  {children.length > 0 && (
                    <div className="content mb-0">
                      <div className="todo-list list-group list-custom-small">
                        {children.map((cItem, cIndex) => (
                          <a key={cIndex} href="/" onClick={(event) => event.preventDefault()}>
                            <i
                              className={`${
                                cart[cItem.alias] ? 'fa fa-check bg-green1-dark' : 'fas fa-cart-arrow-down bg-red2-dark'
                              } rounded-l color-white font-12`}
                            />
                            <span>{cItem.title} </span> <br />{' '}
                            <span>
                              {[`${cItem.price} ${i18n.currency}`, cItem.weight].filter((v) => v).join(' / ')}
                            </span>
                            <button
                              className={`btn btn-block btn-xxs mb-3 rounded-s text-uppercase font-900 shadow-s ${
                                !cart[cItem.id]
                                  ? 'border-red2-dark  bg-red2-light'
                                  : 'border-green1-dark  bg-green1-light'
                              }`}
                              onClick={(event) => {
                                event.preventDefault();
                                setShowAddToCart(cItem);
                              }}
                            >
                              {i18n['add-to-cart']}
                            </button>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          {showAddToCart && (
            <>
              <div
                style={{ height: 'auto', display: 'block' }}
                className="animate__animated animate__bounceIn pb-5 menu menu-box-bottom menu-box-detached rounded-m menu-active"
              >
                <div className="boxed-text-xl">
                  <h3 className="text-uppercase mt-4 font-900">{showAddToCart.title}</h3>
                  <div className="">
                    <input
                      type="text"
                      className="font-20 text-center border-0 mt-5 mb-5 bg-transparent"
                      value={qty}
                      onChange={(event) => {
                        if (event.target.value === '') {
                          setQty('');
                        } else if (Number(event.target.value) && Number(event.target.value) <= showAddToCart.max) {
                          setQty(Number(event.target.value));
                        } else {
                          setQty(qty);
                        }
                      }}
                    />
                  </div>
                  <button
                    disabled={!qty}
                    onClick={(event) => {
                      event.preventDefault();
                      event.preventDefault();
                      dispatch(appAddCart({ ...cart, [showAddToCart.alias]: qty || 1 }));
                      dispatch(appSetShowAddToCart(true));
                      setShowAddToCart(null);
                    }}
                    className="btn btn-s rounded-s shadow-l bg-highlight btn-center-m text-uppercase font-900"
                  >
                    {i18n['add-to-cart']}
                  </button>
                </div>
              </div>
              <div
                className="menu-hider menu-active"
                onClick={(event) => {
                  event.preventDefault();
                  setShowAddToCart(null);
                }}
                style={{ transform: 'translate(0px, 0px)' }}
              >
                <div />
              </div>
            </>
          )}
        </>
      </Content>
    </>
  );
};
