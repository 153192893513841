import React, { useEffect } from 'react';
import { Footer, Loader, SideBar } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { appSetLang, appSetShowAddToCart, appSetShowSelectLang, initApp } from './store/actions';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  Home,
  Services,
  RoomServices,
  Features,
  HotSiberia,
  Feedback,
  RoomServicesMenu,
  Cart,
  Places,
  RoomServicesBar,
  RoomServicesRestaurant,
  RoomServicesBreakfast,
} from './pages';
import { useTheme } from './hooks/useTheme';
import { useDevice } from './hooks/useDevice';
import { Preview } from './components/Preview/Preview';
const App = () => {
  const showPreview = useSelector((state) => state.app.showPreview);
  const errorMessage = useSelector((state) => state.app.errorMessage);
  const successMessage = useSelector((state) => state.app.successMessage);
  const showSideBar = useSelector((state) => state.app.showSideBar);
  const loading = useSelector((state) => state.app.loading);
  const i18n = useSelector((state) => state.app.i18n || {});
  const showAddToCart = useSelector((state) => state.app.showAddToCart);
  const showSelectLang = useSelector((state) => state.app.showSelectLang);
  const lang = useSelector((state) => state.app.lang);
  const dispatch = useDispatch();
  useTheme();
  useDevice();
  useEffect(() => {
    dispatch(initApp(lang !== 'ru' ? lang : null));
  }, [dispatch, lang]);
  useEffect(() => {
    const timer = showAddToCart
      ? setTimeout(() => {
          dispatch(appSetShowAddToCart(false));
        }, 3000)
      : null;
    return () => timer && clearTimeout(timer);
  }, [showAddToCart, dispatch]);
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          {showPreview && <Preview />}
          {!showPreview && (
            <BrowserRouter>
              <div id="page">
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/hotsiberia">
                    <HotSiberia />
                  </Route>
                  <Route exact path="/features">
                    <Features />
                  </Route>
                  <Route exact path="/room-services">
                    <RoomServices />
                  </Route>
                  <Route exact path="/room-services/menu">
                    <RoomServicesMenu />
                  </Route>
                  <Route exact path="/room-services/bar">
                    <RoomServicesBar />
                  </Route>
                  <Route exact path="/room-services/restaurant">
                    <RoomServicesRestaurant />
                  </Route>
                  <Route exact path="/room-services/breakfast">
                    <RoomServicesBreakfast />
                  </Route>
                  <Route exact path="/places">
                    <Places />
                  </Route>
                  <Route exact path="/feedback">
                    <Feedback />
                  </Route>
                  <Route exact path="/services">
                    <Services />
                  </Route>
                  <Route exact path="/cart">
                    <Cart />
                  </Route>
                  <Route exact path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
                <Footer />
                {showSideBar && <SideBar />}
                {errorMessage}
                {successMessage}
                {showAddToCart && (
                  <>
                    <div
                      className="animate__animated animate__bounceIn w-100 alert alert-small rounded-0 shadow-xl bg-green1-dark position-fixed"
                      style={{ top: 0, zIndex: 1000 }}
                      role="alert"
                    >
                      <span>
                        <i className="fa fa-check" />
                      </span>
                      <strong>{i18n['menu-added']}</strong>
                      <button
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          dispatch(appSetShowAddToCart(false));
                        }}
                        className="close color-white opacity-60 font-16"
                      >
                        ×
                      </button>
                    </div>
                  </>
                )}
                {showSelectLang && (
                  <>
                    <div
                      className="menu menu-box-bottom menu-box-detached rounded-m menu-active"
                      style={{ display: 'block', height: 'auto' }}
                    >
                      <div className="mr-3 ml-3 mt-3">
                        <h1 className="text-uppercase font-900 mb-0">{i18n.langs}</h1>
                        <div className="list-group list-custom-small">
                          <a
                            href="/"
                            onClick={(event) => {
                              event.preventDefault();
                              lang !== 'ru' && dispatch(appSetLang('ru'));
                              dispatch(appSetShowSelectLang(false));
                            }}
                          >
                            <img
                              className="mr-3 mt-n1"
                              width="20"
                              alt={'ru'}
                              src="/assets/front/images/flags/Russia.png"
                            />
                            <span>{i18n.ru}</span>
                            <i className="fa fa-angle-right" />
                          </a>
                          <a
                            href="/"
                            onClick={(event) => {
                              event.preventDefault();
                              lang !== 'en' && dispatch(appSetLang('en'));
                              dispatch(appSetShowSelectLang(false));
                            }}
                          >
                            <img
                              className="mr-3 mt-n1"
                              width="20"
                              alt={'en'}
                              src="/assets/front/images/flags/United-States.png"
                            />
                            <span>{i18n.en}</span>
                            <i className="fa fa-angle-right" />
                          </a>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <div
                      className="menu-hider menu-active"
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch(appSetShowSelectLang(false));
                      }}
                      style={{ transform: 'translate(0px, 0px)' }}
                    >
                      <div />
                    </div>
                  </>
                )}
              </div>
            </BrowserRouter>
          )}
        </>
      )}
    </>
  );
};

export default App;
