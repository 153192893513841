import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSetDark, appSetShowSideBar } from '../../store/actions';
import { Link } from 'react-router-dom';
import { useCart } from '../../hooks/useCart';
export const SideBar = () => {
  const isDark = useSelector((state) => state.app.isDark);
  const i18n = useSelector((state) => state.app.i18n || {});
  const name = useSelector((state) => state.app.name || null);
  const app = useSelector((state) => state.app.blocks.app || []);
  const socials = useSelector((state) => state.app.blocks.socials || []);
  const dispatch = useDispatch();
  const cart = useCart();
  const count = Object.keys(cart).length;
  useEffect(() => {
    document.body.classList.toggle('modal-open', true);
    return () => document.body.classList.toggle('modal-open', false);
  }, []);
  const getIcon = (soc) => {
    switch (soc.title) {
      case 'facebook':
        return <i className="fab font-12 fab fa-facebook-f" />;
      case 'vk':
        return <i className="fab font-12 fab fa-vk" />;
      case 'instagram':
        return <i className="fab font-12 fab fa-instagram" />;
      case 'youtube':
        return <i className="fab font-12 fab fa-youtube" />;
      default:
        return <i className="fab font-12 fa fa-share-alt" />;
    }
  };
  const getBgIcon = (soc) => {
    switch (soc.title) {
      case 'facebook':
        return 'color-facebook';
      case 'vk':
        return 'color-twitter';
      case 'instagram':
        return 'color-instagram';
      case 'youtube':
        return 'color-red2-dark';
      default:
        return 'color-red2-dark';
    }
  };
  const getIconMenu = (soc) => {
    switch (soc.title) {
      case 'facebook':
        return <i className="fab rounded-xl font-12 fab fa-facebook-f bg-facebook" />;
      case 'vk':
        return <i className="fab rounded-xl font-12 fab fa-vk bg-twitter" />;
      case 'instagram':
        return <i className="fab rounded-xl font-12 fab fa-instagram bg-instagram" />;
      case 'youtube':
        return <i className="fab rounded-xl font-12 fab fa-youtube bg-red2-dark" />;
      default:
        return <i className="fab rounded-xl font-12 fa fa-share-alt bg-red2-dark" />;
    }
  };
  return (
    <>
      <div className="bg-white menu menu-box-left menu-active" style={{ width: 320, display: 'block' }}>
        <div className="d-flex">
          {socials.map((s, index) => (
            <a
              href={s.link}
              key={index}
              className={`flex-fill icon icon-m text-center ${getBgIcon(s)} border-bottom border-right`}
            >
              {getIcon(s)}
            </a>
          ))}
          <a
            href="/"
            onClick={(event) => {
              event.preventDefault();
              dispatch(appSetShowSideBar(false));
            }}
            className="close-menu flex-fill icon icon-m text-center color-red2-dark border-bottom"
          >
            <i className="fa font-12 fa-times" />
          </a>
        </div>
        <div className="mr-3 ml-3">
          <span className="text-uppercase font-900 font-11 color-red2-dark">{name}</span>
          <div className="list-group list-custom-small list-icon-0">
            {count > 0 && (
              <Link to={'/cart'} onClick={() => dispatch(appSetShowSideBar(false))}>
                <i className={`font-14 fas fa-cart-arrow-down color-red2-dark`} />
                <span>{i18n.cart}</span>
                <span className="badge bg-red2-dark">{count}</span>
                <i className="fa fa-angle-right" />
              </Link>
            )}
            {app.map((item, index) => {
              return (
                <Link
                  key={index}
                  onClick={(event) => {
                    if ((item.link && item.link.search('http://') === 0) || item.link.search('https://') === 0) {
                      event.preventDefault();
                      window.location.replace(item.link);
                    }
                    dispatch(appSetShowSideBar(false));
                  }}
                  to={item.link}
                >
                  <i className={`font-14 ${item.icon} color-red2-dark`} />
                  <span>{item.title}</span>
                  <i className="fa fa-angle-right" />
                </Link>
              );
            })}
          </div>
        </div>
        <div className="mr-3 ml-3 mt-4">
          <span className="text-uppercase font-900 font-11 opacity-30">{i18n.social}</span>
          <div className="list-group list-custom-small">
            {socials.map((s, index) => (
              <a href={s.link} key={index}>
                {getIconMenu(s)}
                {s.title && <span>{s.title.toUpperCase()}</span>}
                <i className="fa fa-link" />
              </a>
            ))}
          </div>
        </div>
        <div className="mr-3 ml-3 mt-4 pt-2">
          <span className="text-uppercase font-900 font-11 opacity-30">{i18n.settings}</span>
          <div className="list-group list-custom-small list-icon-0">
            <a href="/">
              <i className="fa font-14 fa-moon color-red2-dark" />
              <span>{i18n['dark-mode']}</span>
              <div className="custom-control scale-switch ios-switch">
                <input
                  id="sidebar-dark-mode"
                  type="checkbox"
                  className="ios-input"
                  defaultValue={isDark}
                  onChange={() => {
                    dispatch(appSetDark(!isDark));
                  }}
                  checked={isDark}
                />
                <label className="custom-control-label" htmlFor="sidebar-dark-mode" />
              </div>
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
      </div>
      <div
        className="menu-hider menu-active"
        onClick={(event) => {
          event.preventDefault();
          dispatch(appSetShowSideBar(false));
        }}
        style={{ transform: `translate(0px, 0px)` }}
      >
        <div />
      </div>
    </>
  );
};
