import { useSelector } from 'react-redux';

export const useCart = () => {
  const res = {};
  const cart = useSelector((state) => state.app.cart || {});
  const products = useSelector((state) => (state.app.blocks.products || []).filter((p) => p.parent_id));
  Object.keys(cart)
    .filter((k) => products.some((p) => p.alias === k))
    .forEach((k) => {
      res[k] = cart[k] || 1;
    });
  return res;
};
