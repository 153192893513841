import React, { useState } from 'react';
import { Content, Header } from '../../components';
import { useSelector } from 'react-redux';
export const Places = () => {
  const i18n = useSelector((state) => state.app.i18n || {});
  const places = useSelector((state) => state.app.blocks.places || []);
  const [showPlace, setShowPlace] = useState(null);
  return (
    <>
      <Header title={i18n.places} />
      <Content>
        <>
          <div className="card card-style">
            <div className="content mb-3">
              <div className="row text-center mb-0">
                {places.map((item, index) => (
                  <a
                    href={'/'}
                    onClick={(event) => {
                      event.preventDefault();
                      setShowPlace(item);
                    }}
                    key={index}
                    className="col-12 p-0"
                  >
                    <div
                      className={`card card-style ml-2 mb-3 pb-3 bg-${index + 1}`}
                      style={{ backgroundImage: `url('${item.img}')` }}
                    >
                      <i className={`${item.icon} color-white fa-3x mt-5`} />
                      <h6 className="pt-4 color-white">{item.title}</h6>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </>
      </Content>
      {showPlace && (
        <>
          <div
            style={{ height: 'auto', display: 'block' }}
            className="animate__animated animate__bounceIn pb-5 menu menu-box-bottom menu-box-detached rounded-m menu-active"
          >
            <div className="boxed-text-xl">
              <h3 className="text-uppercase mt-4 font-900">{showPlace.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: showPlace.content }} />
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setShowPlace(null);
                }}
                data-menu="menu-tour-2"
                className="btn btn-s rounded-s shadow-l bg-highlight btn-center-m text-uppercase font-900"
              >
                {i18n.close}
              </a>
            </div>
          </div>
          <div
            className="menu-hider menu-active"
            onClick={(event) => {
              event.preventDefault();
              setShowPlace(null);
            }}
            style={{ transform: 'translate(0px, 0px)' }}
          >
            <div />
          </div>
        </>
      )}
    </>
  );
};
