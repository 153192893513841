import React, { useState } from 'react';
import { Content, Header } from '../../components';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export const Services = () => {
  const [showService, setShowService] = useState(null);
  const i18n = useSelector((state) => state.app.i18n || {});
  const menu = useSelector((state) => state.app.blocks.menu || []);
  const services = useSelector((state) => state.app.blocks.services || []);
  return (
    <>
      <Header title={(menu.find((m) => m.link === '/services') || {}).title} />
      <Content>
        <div className="card card-style">
          <div className="content mb-3">
            <div className="row text-center row-cols-2 mb-0">
              {services.map((item, index) => {
                return (
                  <a
                    className="col default-link"
                    data-lightbox="gallery-1"
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      item.preview && setShowService(item);
                    }}
                    key={index}
                    title={item.title}
                  >
                    {item.icon && (
                      <LazyLoadImage
                        effect="blur"
                        src={item.icon}
                        className="preload-img img-fluid rounded-xs"
                        alt={item.title}
                      />
                    )}
                    <p className="font-600 pb-1">{item.title}</p>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </Content>
      {showService && (
        <>
          <div
            style={{ height: 'auto', display: 'block' }}
            className="animate__animated animate__bounceIn pb-5 menu menu-box-bottom menu-box-detached rounded-m menu-active"
          >
            <div className="boxed-text-xl">
              <h3 className="text-uppercase mt-4 font-900">{showService.title}</h3>
              <p>{showService.preview}</p>
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setShowService(null);
                }}
                data-menu="menu-tour-2"
                className="btn btn-s rounded-s shadow-l bg-highlight btn-center-m text-uppercase font-900"
              >
                {i18n.close}
              </a>
            </div>
          </div>
          <div
            className="menu-hider menu-active"
            onClick={(event) => {
              event.preventDefault();
              setShowService(null);
            }}
            style={{ transform: 'translate(0px, 0px)' }}
          >
            <div />
          </div>
        </>
      )}
    </>
  );
};
