import React from 'react';
import { Content, Header } from '../../../components';
import { useSelector } from 'react-redux';
import { RoomServiceForm } from '../../../components/Forms/RoomServiceForm/RoomServiceForm';
export const RoomServicesRestaurant = () => {
  const roomService =
    useSelector((state) => state.app.blocks.roomService || []).find((m) => m.link === '/room-services/restaurant') ||
    {};
  return (
    <>
      <Header title={roomService.title} />
      <Content>
        <>
          <RoomServiceForm roomService={roomService} />
        </>
      </Content>
    </>
  );
};
