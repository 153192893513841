import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedbackAppAPI } from '../../../api/sendFeedbackAppAPI';
import { appSetError, appSetSuccess } from '../../../store/actions';
import { ErrorMessage, SuccessMessage } from '../..';
export const FeedbackForm = () => {
  const _token = useSelector((state) => state.app._token || null);
  const i18n = useSelector((state) => state.app.i18n || []);
  const isDark = useSelector((state) => state.app.isDark);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState({
    name: '',
    phone: '',
    comment: '',
  });
  const canSubmit = () => {
    return (
      !loading &&
      model.name.length > 0 &&
      model.name.length <= 255 &&
      model.phone.length > 0 &&
      model.phone.length <= 255 &&
      model.comment.length > 0 &&
      model.comment.length <= 1000
    );
  };
  const onSubmit = () => {
    setLoading(true);
    sendFeedbackAppAPI({
      ...model,
      _token,
    })
      .then((data) => {
        if (data.success) {
          setModel({
            name: '',
            phone: '',
            comment: '',
          });
          dispatch(
            appSetSuccess(
              <SuccessMessage onClose={() => dispatch(appSetSuccess(null))}>
                <>
                  <h1 className="text-center mt-3 text-uppercase font-900 color-white">{i18n['success']}</h1>
                  <p className="boxed-text-l color-white opacity-70">{i18n['success-message']}</p>
                </>
              </SuccessMessage>,
            ),
          );
          return;
        }
        return Promise.reject();
      })
      .catch(() =>
        dispatch(
          appSetError(
            <ErrorMessage onClose={() => dispatch(appSetError(null))}>
              <>
                <h1 className="text-center mt-3 text-uppercase font-900 color-white">{i18n['error']}</h1>
                <p className="boxed-text-l color-white opacity-70">{i18n['error-message']}</p>
              </>
            </ErrorMessage>,
          ),
        ),
      )
      .finally(() => setLoading(false));
  };
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          canSubmit() && onSubmit();
        }}
        className="card card-style"
      >
        <div className="content mb-0">
          <h3 className="bolder">{i18n.feedback}</h3>
          {loading && (
            <div className="row mt-5 mb-5">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border color-red2-dark" role="status">
                    <span className="sr-only">{i18n.loading}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && (
            <>
              <div className="input-style has-icon input-style-1 input-required">
                <i className={`input-icon fa fa-user color-red2-dark`} />
                <em>
                  {model.name.length === 0 && <>({i18n.required}) </>}
                  {model.name.length} / 255
                </em>
                <input
                  type="text"
                  maxLength={255}
                  value={model.name}
                  onChange={(event) => setModel({ ...model, name: event.target.value })}
                  placeholder={i18n.fio}
                />
              </div>

              <div className="input-style has-icon input-style-1 input-required">
                <i className={`input-icon fas fa-phone-volume color-red2-dark`} />
                <em>
                  {model.phone.length === 0 && <>({i18n.required}) </>}
                  {model.phone.length} / 255
                </em>
                <input
                  type="text"
                  maxLength={255}
                  value={model.phone}
                  onChange={(event) => setModel({ ...model, phone: event.target.value })}
                  placeholder={i18n.phone}
                />
              </div>
              <div className="input-style input-style-1 input-required">
                <em>
                  {model.comment.length === 0 && <>({i18n.required}) </>}
                  {model.comment.length} / 1000
                </em>
                <textarea
                  cols={10}
                  className={isDark ? 'text-white' : ''}
                  maxLength={1000}
                  value={model.comment}
                  onChange={(event) => setModel({ ...model, comment: event.target.value })}
                  placeholder={i18n.message}
                />
              </div>
              <div className="content">
                <button
                  type={'submit'}
                  disabled={!canSubmit()}
                  className="btn btn-full btn-block font-900 text-uppercase bg-red2-dark btn-m rounded-sm"
                >
                  {i18n.send}
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};
