import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
const isDev = process.env.NODE_ENV !== 'production';
const middlewares = [];
if (isDev) {
  middlewares.push(logger);
}
middlewares.push(thunk);
const store = createStore(
  rootReducer,
  isDev ? composeWithDevTools(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares),
);

export default store;
