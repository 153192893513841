export const sendMenuAppAPI = (data) =>
  fetch('/api/order/menu', {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    return res.ok ? res.json() : Promise.reject();
  });
