import { USER_LOCAL_STORAGE } from '../constants';

export const getUserLocalStorage = () => {
  let res = {};
  try {
    res = JSON.parse(window.localStorage.getItem(USER_LOCAL_STORAGE)) || {};
  } catch (e) {}
  return res;
};

export const setUserLocalStorage = (data) => {
  const localStorage = getUserLocalStorage();
  try {
    window.localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(Object.assign(localStorage, data)));
  } catch (e) {
    console.log(e, data);
  }
};
