import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useTheme = () => {
  const isDark = useSelector((state) => state.app.isDark);
  useEffect(() => {
    document.body.classList.toggle('theme-dark', isDark);
    document.body.classList.toggle('theme-light', !isDark);
  }, [isDark]);
};
