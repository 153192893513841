import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
export const SuccessMessage = (props) => {
  const { children = null, onClose = () => {} } = props;
  const i18n = useSelector((state) => state.app.i18n || {});
  return (
    <>
      <div
        className="animate__animated animate__bounceIn menu menu-box-bottom menu-box-detached bg-green1-dark rounded-m menu-active"
        style={{ height: 305, display: 'block' }}
      >
        <h1 className="text-center mt-4">
          <i className="fa fa-3x fa-check-circle color-white shadow-xl rounded-circle" />
        </h1>
        {children}
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
          className="close-menu btn btn-m btn-center-m button-s shadow-l rounded-s text-uppercase font-900 bg-white"
        >
          {i18n.close}
        </a>
      </div>
      <div
        className="menu-hider menu-active"
        onClick={(event) => {
          event.preventDefault();
          onClose();
        }}
        style={{ transform: 'translate(0px, 0px)' }}
      >
        <div />
      </div>
    </>
  );
};

SuccessMessage.propTypes = {
  children: PropTypes.element,
  onClose: PropTypes.func,
};
