import { isMobile } from './../lib/common';
export const useDevice = () => {
  if (!isMobile.any()) {
    document.body.classList.toggle('is-not-ios', true);
  }
  if (isMobile.Android()) {
    document.body.classList.toggle('is-not-ios', true);
    var meta = document.createElement('meta');
    meta.name = 'theme-color';
    meta.content = '#FFFFFF';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
  if (isMobile.iOS()) {
    document.body.classList.toggle('is-ios', true);
  }
};
