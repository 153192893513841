import { setUserLocalStorage } from '../../lib/localStorage';
import { initAppAPI } from '../../api/initAppAPI';

export const APP_SET_SHOW_PREVIEW = 'APP_SET_SHOW_PREVIEW';
export const APP_INIT = 'APP_INIT';
export const APP_SET_LANG = 'APP_SET_LANG';
export const APP_SET_LOADING = 'APP_SET_LOADING';
export const APP_SET_DARK = 'APP_SET_DARK';
export const APP_SET_SIDE_BAR = 'APP_SET_SIDE_BAR';
export const APP_ADD_CART = 'APP_ADD_CART';
export const APP_SET_ERROR = 'APP_SET_ERROR';
export const APP_SET_SUCCESS = 'APP_SET_SUCCESS';
export const APP_SET_SHOW_ADD_TO_CART = 'APP_SET_SHOW_ADD_TO_CART';
export const APP_SET_SHOW_SELECT_LANG = 'APP_SET_SHOW_SELECT_LANG';
export const appSetShowSelectLang = (payload) => {
  return {
    type: APP_SET_SHOW_SELECT_LANG,
    payload,
  };
};
export const appSetShowAddToCart = (payload) => {
  return {
    type: APP_SET_SHOW_ADD_TO_CART,
    payload,
  };
};
export const appSetError = (payload) => {
  return {
    type: APP_SET_ERROR,
    payload,
  };
};
export const appSetSuccess = (payload) => {
  return {
    type: APP_SET_SUCCESS,
    payload,
  };
};

export const appSetShowSideBar = (payload) => {
  return {
    type: APP_SET_SIDE_BAR,
    payload,
  };
};
export const appSetShowPreview = (payload) => {
  setUserLocalStorage({ showPreview: payload });
  return {
    type: APP_SET_SHOW_PREVIEW,
    payload,
  };
};

export const appAddCart = (payload) => {
  setUserLocalStorage({ cart: payload });
  return {
    type: APP_ADD_CART,
    payload,
  };
};

export const appSetLang = (payload) => (dispatch) => {
  setUserLocalStorage({ lang: payload });
  dispatch({
    type: APP_SET_LANG,
    payload,
  });
  initApp(payload !== 'ru' ? payload : null)(dispatch);
};

export const appSetDark = (payload) => {
  setUserLocalStorage({ isDark: payload });
  return {
    type: APP_SET_DARK,
    payload,
  };
};

export const appSetLoading = (payload) => {
  return {
    type: APP_SET_LOADING,
    payload,
  };
};

export const appInit = (payload) => {
  return {
    type: APP_INIT,
    payload,
  };
};

export const initApp = (lang = null) => (dispatch) => {
  dispatch(appSetLoading(true));
  initAppAPI(lang)
    .then((data) => dispatch(appInit(data)))
    .finally(() => dispatch(appSetLoading(false)));
};
