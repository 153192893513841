import React, { useState } from 'react';
import { Content, Header } from '../../components';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export const Features = () => {
  const menu = useSelector((state) => state.app.blocks.menu || []);
  const i18n = useSelector((state) => state.app.i18n || {});
  const features = useSelector((state) => state.app.blocks.features || []);
  const [showFeature, setShowFeature] = useState(null);
  return (
    <>
      <Header title={(menu.find((m) => m.link === '/features') || {}).title} />
      <Content>
        <div className="card card-style">
          <div className="content mb-3">
            <div className="row text-center row-cols-2 mb-0">
              {features.map((item, index) => {
                return (
                  <a
                    className="col default-link"
                    data-lightbox="gallery-1"
                    target={item.link ? '_blank' : '_self'}
                    href={item.link}
                    key={index}
                    title={item.title}
                    onClick={(event) => {
                      if (item.content) {
                        event.preventDefault();
                        setShowFeature(item);
                      }
                    }}
                  >
                    {item.icon && (
                      <LazyLoadImage
                        effect="blur"
                        src={item.icon}
                        className="preload-img img-fluid rounded-xs"
                        alt={item.title}
                      />
                    )}
                    <p className="font-600 pb-1">{item.title.toUpperCase()}</p>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </Content>
      {showFeature && (
        <>
          <div
            style={{ height: 'auto', display: 'block' }}
            className="animate__animated animate__bounceIn pb-5 menu menu-box-bottom menu-box-detached rounded-m menu-active"
          >
            <div className="boxed-text-xl">
              <h3 className="text-uppercase mt-4 font-900">{showFeature.title}</h3>
              <p>{showFeature.content}</p>
              <a
                href="/"
                onClick={(event) => {
                  event.preventDefault();
                  setShowFeature(null);
                }}
                data-menu="menu-tour-2"
                className="btn btn-s rounded-s shadow-l bg-highlight btn-center-m text-uppercase font-900"
              >
                {i18n.close}
              </a>
            </div>
          </div>
          <div
            className="menu-hider menu-active"
            onClick={(event) => {
              event.preventDefault();
              setShowFeature(null);
            }}
            style={{ transform: 'translate(0px, 0px)' }}
          >
            <div />
          </div>
        </>
      )}
    </>
  );
};
