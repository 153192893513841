import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export const FooterPage = () => {
  const title = useSelector((state) => state.app.name || null);
  const i18n = useSelector((state) => state.app.i18n || {});
  const socials = useSelector((state) => state.app.blocks.socials || []);
  const getIcon = (soc) => {
    switch (soc.title) {
      case 'facebook':
        return <i className="fab fa-facebook-f" />;
      case 'vk':
        return <i className="fab fa-vk" />;
      case 'instagram':
        return <i className="fab fa-instagram" />;
      case 'youtube':
        return <i className="fab fa-youtube" />;
      default:
        return <i className="fa fa-share-alt" />;
    }
  };
  const getBgIcon = (soc) => {
    switch (soc.title) {
      case 'facebook':
        return 'color-white bg-facebook';
      case 'vk':
        return 'color-white bg-twitter';
      case 'instagram':
        return 'color-white bg-instagram';
      case 'youtube':
        return 'color-red2-dark bg-youtube';
      default:
        return 'bg-red2-dark';
    }
  };
  return (
    <div className="footer card card-style">
      <Link to={'/'} className="footer-title">
        <span className="color-highlight">{title}</span>
      </Link>
      {i18n.slogan2 && (
        <p className="footer-text">
          <span dangerouslySetInnerHTML={{ __html: i18n.slogan2 }} />
        </p>
      )}
      <div className="row text-center">
        {socials.map((s, index) => (
          <div key={index} className="col-3 p-2">
            <a
              href={s.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`icon icon-l ${getBgIcon(s)} rounded-s shadow-l`}
            >
              {getIcon(s)}
            </a>
          </div>
        ))}
      </div>
      {i18n.copy && <p className="footer-copyright">{i18n.copy}</p>}
      {i18n.totop && (
        <div className="content mt-1 mb-1">
          <a
            href="/"
            title={title}
            onClick={(event) => {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="text-white back-to-top-badge bg-highlight shadow-xl"
          >
            <span>{i18n.totop}</span>
          </a>
        </div>
      )}
      <div className="clear" />
    </div>
  );
};
