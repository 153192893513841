import {
  APP_ADD_CART,
  APP_INIT,
  APP_SET_DARK,
  APP_SET_ERROR,
  APP_SET_LANG,
  APP_SET_LOADING,
  APP_SET_SHOW_ADD_TO_CART,
  APP_SET_SHOW_PREVIEW,
  APP_SET_SHOW_SELECT_LANG,
  APP_SET_SIDE_BAR,
  APP_SET_SUCCESS,
} from '../actions';
import { getUserLocalStorage } from '../../lib/localStorage';

const initialState = Object.assign(
  {
    _token: null,
    name: null,
    lang: 'ru',
    i18n: {},
    blocks: {},
    showPreview: true,
    loading: true,
    isDark: false,
    config: {},
    cart: {},
    showSideBar: false,
    errorMessage: null,
    successMessage: null,
    showAddToCart: false,
    showSelectLang: false,
  },
  getUserLocalStorage(),
  {},
);

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_SHOW_SELECT_LANG:
      return {
        ...state,
        showSelectLang: action.payload,
      };
    case APP_SET_SHOW_ADD_TO_CART:
      return {
        ...state,
        showAddToCart: action.payload,
      };
    case APP_SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case APP_SET_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
      };
    case APP_SET_SHOW_PREVIEW:
      return {
        ...state,
        showPreview: action.payload,
      };
    case APP_ADD_CART:
      return {
        ...state,
        cart: action.payload,
      };
    case APP_SET_SIDE_BAR:
      return {
        ...state,
        showSideBar: action.payload,
      };
    case APP_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case APP_SET_DARK:
      return {
        ...state,
        isDark: action.payload,
      };
    case APP_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case APP_INIT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
